import { useDispatch, useSelector } from 'react-redux'
import {
  isWishlistLoadingSelector,
  wishlistExternalIdSelector,
  wishlistItemSelector,
  wishlistItemsSelector,
} from '../../../features/wishList/selector'
import { useEffect, useState } from 'react'
import WishlistService from '../../../foundation/apis/transaction/wishList.service'
import { fetchWishlistAction } from '../../../features/wishList/action'
import { AxiosResponse } from 'axios'
import guestIdentityService from '@foundation/apis/transaction/guestIdentity.service'
import { GUEST_LOGIN_SUCCESS_ACTION } from '@redux/actions/user'
import { CreateWishlistResponse, IWishlist, ItemForUpdateWishlistRequest } from '@typesApp/wishlist'
import Log from '@services/Log'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { sessionStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { setWishList } from '@features/wishList/slice'
import { VIRTUAL_MIRROR_WISHLIST_ITEM_ID, VIRTUAL_MIRROR_WISHLIST_TOGGLE } from '@utils/virtualMirrorUtils'

export const useWishlist = currentProduct => {
  const dispatch = useDispatch()
  const wishlistExternalId = useSelector(wishlistExternalIdSelector)
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistedItems = useSelector(wishlistItemsSelector)
  const getItemWishList = useSelector(
    wishlistItemSelector(currentProduct?.partnumberId ? currentProduct?.partnumberId : currentProduct?.partNumber || '')
  )
  const [itemIdWishList, setItemIdWishList] = useState<string>('')
  const { langId } = useStoreIdentity()

  const getGuestIdentity = async () => {
    const payload = { widget: 'axiosConfig' }
    const guestIdentity: AxiosResponse = await guestIdentityService.login(payload)

    dispatch(GUEST_LOGIN_SUCCESS_ACTION({ ...guestIdentity.data, ...payload }))
  }

  const getWishlist = async () => {
    return await WishlistService.getDefault()
      .then((res: IWishlist) => res)
      .catch(error => {
        Log.error('Get wishlist error: ' + error)
        return undefined
      })
  }

  const createWishlist = async (): Promise<string> => {
    return await WishlistService.createWishlist()
      .then((res: CreateWishlistResponse) => res.uniqueID)
      .catch(error => {
        Log.error('Create wishlist error: ' + error)
        return ''
      })
  }

  const addItemToWishlist = async (
    wishListId: string,
    item: ItemForUpdateWishlistRequest[],
    langId: string,
    dispatch
  ) => {
    await WishlistService.addItemToWishlist(wishListId, item, langId)

    dispatch(fetchWishlistAction())
  }

  const addToWishList = async () => {
    try {
      const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

      let wishListId = ''

      //if generic user, call the guest identity
      if (!currentUser) {
        dispatch(setWishList({ isLoading: true }))
        await getGuestIdentity()
        wishListId = await createWishlist()
      } else {
        //if NOT generic user, call the get wishlist
        const wishList = await getWishlist()
        if (!!wishList) {
          wishListId = wishList.GiftList[0].uniqueID
        } else {
          wishListId = await createWishlist()
        }
      }

      if (!!wishListId && wishListId.length > 0) {
        await addItemToWishlist(
          wishListId,
          [
            {
              partNumber: currentProduct.partnumberId ? currentProduct.partnumberId : currentProduct.partNumber,
              quantityRequested: '1',
              location: 'online',
            },
          ],
          langId,
          dispatch
        )
      } else {
        throw new Error()
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        Log.error('Add wishlist error: ' + error)
      }
    }
  }

  const removeFromWishList = async (avoidRefetchWishlist?: boolean) => {
    const itemId = sessionStorageUtil.get(VIRTUAL_MIRROR_WISHLIST_ITEM_ID) ?? itemIdWishList
    if (itemId) {
      await WishlistService.deleteItemFromWishlist({
        externalId: wishlistExternalId,
        itemId,
      })

      !avoidRefetchWishlist && dispatch(fetchWishlistAction())
    } else {
      Log.error('Remove wishlist error: itemId is not found')
      throw new Error()
    }
  }

  const determineWishListAction = async (isFromVM?: boolean, avoidRefetchWishlist?: boolean) => {
    if (isFromVM) {
      const vmWishListLastAction = sessionStorageUtil.get(VIRTUAL_MIRROR_WISHLIST_TOGGLE)
      if (vmWishListLastAction && vmWishListLastAction === 'wishlisted') {
        await removeFromWishList(avoidRefetchWishlist)
        sessionStorageUtil.remove(VIRTUAL_MIRROR_WISHLIST_TOGGLE)
      } else {
        await addToWishList()
        sessionStorageUtil.set(VIRTUAL_MIRROR_WISHLIST_TOGGLE, 'wishlisted')
      }
    } else {
      getItemWishList ? removeFromWishList(avoidRefetchWishlist) : addToWishList()
    }
  }

  useEffect(() => {
    if (currentProduct) {
      const partNumberId =
        'partnumberId' in currentProduct
          ? currentProduct.partnumberId
          : 'partNumber' in currentProduct
            ? currentProduct.partNumber
            : ''
      if (getItemWishList && getItemWishList.partNumber === partNumberId) {
        setItemIdWishList(getItemWishList.giftListItemID)
        sessionStorageUtil.set(VIRTUAL_MIRROR_WISHLIST_ITEM_ID, getItemWishList.giftListItemID)
      } else {
        setItemIdWishList('')
        sessionStorageUtil.set(VIRTUAL_MIRROR_WISHLIST_ITEM_ID, '')
      }
    }
  }, [currentProduct, getItemWishList])

  return {
    itemIdWishList,
    isWishlistLoading,
    wishlistedItems,
    setItemIdWishList,
    determineWishListAction,
  }
}
