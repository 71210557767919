import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS, PRODUCT_TYPES_MAP } from '@constants/product'
import {
  getAlgoliaEventParameters,
  storeAlgoliaEventParameters,
  transformAttachmentsToImage,
} from '@features/plp/algoliaUtils'
import { setMocoOrderedIds } from '@features/product/slice'
import { setLastSelectedProduct } from '@features/ui/action'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { getUserToken } from '@foundation/hooks/useAnalyticsData'
import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { plpBadgesSelector } from '@redux/selectors/site'
import {
  getBadges,
  getBrand,
  getCLBrand,
  getIsRoxable,
  getModelCodeDisplay,
  getModelName,
  getProductColorLabel,
  getProductType,
  getSoldOut,
} from '@utils/productAttributesAlgolia'
import ProductImage, { PLP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE, ProductImageProps } from '../ProductImage/ProductImage'
import {
  StyledCLProductBrandName,
  StyledImageWrapper,
  StyledProductAnchor,
  StyledProductBrandName,
  StyledProductDescriptionAnchor,
  StyledProductName,
  StyledProductSubWrapper,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileImageContainer,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledSquare,
} from './components/ProductTile.style'
import ProductTileGalleryAlgolia from './components/ProductTileGalleryAlgolia'
import { IAlgoliaHit } from '@typesApp/product'
import { useGetProductImageWidth } from '@hooks/useGetProductImageWidth'
import config from '@configs/index'
import ProductPriceAlgolia from '@views/ProductDetails/components/ProductPriceAlgolia'
import { shouldShowAbsoluteDiscount } from '@foundation/algolia/algoliaPrice'
import { algoliaEventTrigger } from '@foundation/algolia/algoliaConfig'
import { useSearchParams } from 'next/navigation'
import { getProductImageAltLabel } from '@utils/productImage'
import { WishListButton } from '@components/features/WishlistButton'
import { useProductSoldOutState } from '@views/ProductDetails/useProductSoldOutState'
import { ProductBadges } from '@components/features/ProductBadges'
import styles from './styles/ProductTile.module.scss'
import { ProductPriceSoldOutBox } from '@views/ProductDetails/components/ProductPrice.style'
import { ALGOLIA_FILTERS } from '@foundation/constants/common'

export interface ProductTileAlgoliaProps {
  product: IAlgoliaHit
  tileIndex: number
  variant: string
  onClick?: () => void
  isClustered?: boolean
  isSearchLayer?: boolean
  isPDP?: boolean
  isLoggedIn?: boolean
}

const siteName = config.name

const ProductTileAlgolia: FC<ProductTileAlgoliaProps> = ({
  product,
  tileIndex,
  variant,
  onClick,
  isClustered = true,
  isSearchLayer = false,
  isPDP = false,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { pageType } = usePageType()
  const { isRXEnabled, langCode } = useStoreIdentity()
  const { isDesktop } = useBreakpoints()
  const moCoOrderedIds = product?.clusters?.map(item => item.parentProductId) ?? []
  const [sentClickedObjectIDsEvent, setSentClickedObjectIDsEvent] = useState<{
    [key: string]: boolean
  }>({})
  const [isFirstView, setIsFirstView] = useState<boolean>(true)
  let { soldOutStatus } = useProductSoldOutState({
    currentProductPartNumber: product.partNumber,
    product,
  })

  const soldOutLabel = getSoldOut(product)?.toUpperCase()

  if (soldOutLabel === PRODUCT_SOLDOUT_STATUS.SOLDOUT) {
    soldOutStatus = PRODUCT_SOLDOUT_STATUS.SOLDOUT
  }

  const productType = (product !== null && PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()]) || 'frames'

  const isAccessories = () => productType === PRODUCT_TYPES_KEYS.ACCESSORIES
  const isContactLenses = () => productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES
  const isCLAccessories = () => productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

  const badgeSelector = useSelector(plpBadgesSelector)
  const [carouselMode, setCarouselMode] = useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<IAlgoliaHit>(product)
  const [clusters, setClusters] = useState<IAlgoliaHit[] | undefined>(product?.clusters || [])
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const { primaryBadge, secondaryBadges } = getBadges(product, t, badgeSelector, isRXEnabled)

  const name = getModelName(product)
  const brand = getBrand(product)
  const modelCode = getModelCodeDisplay(product)

  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)

  const mobileListingLayoutBasedOnPages = pageType === 'search' ? 'compact' : productsMobileListingLayout

  const selectedViewCluster = useMemo(
    () => (clusters && clusters.length > 0 ? clusters[selectedClusterIndex] : product),
    [currentProduct?.productId, currentProduct?.clusters, selectedClusterIndex]
  )

  const isRoxable = useMemo(() => selectedViewCluster && getIsRoxable(selectedViewCluster), [selectedViewCluster])

  const isMoCoSliderRenderedForAccessories = isAccessories() && clusters && clusters?.length > 1
  const isMoCoSliderRendered =
    (clusters && !isAccessories() && !isContactLenses() && !isCLAccessories()) || isMoCoSliderRenderedForAccessories
  const isMoCoSliderDisplayed = isMoCoSliderRendered && (carouselMode || mobileListingLayoutBasedOnPages === 'full')

  const isFullListeningLayout = productsMobileListingLayout === 'full'

  const tileDataElementId = useMemo(() => {
    switch (pageType) {
      case 'search':
        return isSearchLayer ? `SearchPanel_Results_Tile${tileIndex}` : `SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }, [pageType])

  const onProductTileClick = () => {
    storeAlgoliaEventParameters({
      type: 'ungrouped',
      position: tileIndex + 1,
      objectID: selectedViewCluster.objectID,
    })
    storeAlgoliaEventParameters({
      type: 'grouped',
      position: tileIndex + 1,
    })
    dispatch(setMocoOrderedIds(moCoOrderedIds))
  }

  useEffect(() => {
    if (product) {
      setCurrentProduct(product)
      setClusters(product.clusters)
    }
  }, [product, selectedViewCluster])

  useEffect(() => {
    if (
      (selectedClusterIndex !== 0 || (selectedClusterIndex === 0 && !isFirstView)) &&
      !Object.keys(sentClickedObjectIDsEvent).includes(selectedViewCluster.objectID)
    ) {
      algoliaEventTrigger('sendEvents', [
        {
          eventType: 'view',
          userToken: window.utag_data.User_Email_MD5 || getUserToken(),
          index: getAlgoliaEventParameters(['indexName'], 'ungrouped').indexName || '',
          eventName: 'main_title_result_page_changed',
          objectIDs: [selectedViewCluster.objectID],
          queryID: getAlgoliaEventParameters(['queryID'], 'ungrouped').queryID,
        },
      ])
      setSentClickedObjectIDsEvent({
        ...sentClickedObjectIDsEvent,
        [selectedViewCluster.objectID]: true,
      })
    }
  }, [selectedViewCluster, selectedClusterIndex])

  useEffect(() => {
    if (selectedClusterIndex !== 0 && isFirstView) {
      setIsFirstView(false)
    }
  }, [selectedClusterIndex, isFirstView])

  const onProductTileMouseEnter = () => {
    if (clusters && clusters.length > 0 && (carouselMode || mobileListingLayoutBasedOnPages === 'full' || isDesktop)) {
      setCarouselMode(true)
    }
  }

  const onProductTileMouseLeave = () => {
    if (carouselMode) {
      setCarouselMode(false)
    }
  }

  const onImageClick = () => {
    dispatch(setLastSelectedProduct(product.productId || ''))
  }

  const saveAlgoliaFilters = () => {
    const currentPlpUrl = `${window.location.pathname}${window.location.search}`
    sessionStorage.setItem(ALGOLIA_FILTERS, currentPlpUrl)
  }

  const commonProductImageProps = {
    alt: getProductImageAltLabel(product, true),
    draggable: false,
    lazy: tileIndex > 5,
    sequence: PLP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE,
    usage: 'PLP' as const,
    width: useGetProductImageWidth(variant),
    onClick: onImageClick,
  }

  const imageConfig = {
    width: 600,
    srcsetmap: {
      320: '320w',
      400: '640w',
      600: '768w',
    },
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: productType === 'accessories' ? '1' : '2',
    usage: 'PLP',
    lazy: tileIndex > 5,
    ...imageConfig,
  }

  const getMocoLinkTo = product => product.url || `/${langCode}`

  const clusterViewLength = clusters?.length ?? 1

  const showBrand = brand && (productType !== 'contact-lenses' || productType !== 'contact-lenses-accessories')

  const showFavouritesIcon =
    !isSearchLayer && productType !== 'contact-lenses' && productType !== 'contact-lenses-accessories'

  const showSoldOutBadge =
    isSearchLayer &&
    (soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK ||
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON)

  return (
    product && (
      <StyledProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        aria-label={primaryBadge || `${name}_${modelCode}`}
        data-element-id={tileDataElementId}
        data-description={`${siteName}_${name}_${modelCode}`}
        className={`${variant === 'suggested' ? 'arn-ProductTile-ymal' : 'arn-ProductTile'}`}
        onClick={onProductTileClick}
      >
        <StyledProductSubWrapper>
          <StyledSquare onClick={onClick}>
            <ProductBadges primaryBadge={primaryBadge} secondaryBadges={secondaryBadges} />

            {!isSearchLayer && isMoCoSliderRendered && isMoCoSliderDisplayed && clusters && (
              <ProductTileGalleryAlgolia
                isDisplayed={isMoCoSliderDisplayed}
                mocoLink={getMocoLinkTo(selectedViewCluster)}
                replaceUrl={searchParams.size > 0}
                tileDataElementId={tileDataElementId}
                priorityBadgeText={primaryBadge}
                commonImageProps={commonGalleryProductImageProps}
                currentProductIndex={selectedClusterIndex}
                onIndexChange={setSelectedClusterIndex}
                cluster={isClustered ? clusters : [product]}
                saveAlgoliaFilters={saveAlgoliaFilters}
              />
            )}

            <StyledProductTileImageContainer
              isFull={isFullListeningLayout}
              className={`arn-product-tile-image ${variant === 'cms-products-module' ? 'arn-product-tile__hp' : ''}`}
              isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
              isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
            >
              <StyledProductAnchor
                aria-label={primaryBadge || `${name}_${modelCode}`}
                href={getMocoLinkTo(selectedViewCluster)}
                className="arn-product-tile__inner"
                data-element-id={tileDataElementId}
                data-description={`${siteName}_${name}_${modelCode}`}
                isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
                callback={saveAlgoliaFilters}
              >
                <StyledImageWrapper shouldZoomOnHover={isCLAccessories() || isContactLenses() || isAccessories()}>
                  <ProductImage
                    attachments={transformAttachmentsToImage(selectedViewCluster?.attachments)}
                    {...commonProductImageProps}
                  />
                </StyledImageWrapper>
              </StyledProductAnchor>
            </StyledProductTileImageContainer>

            {!isSearchLayer && (
              <StyledProductVariants
                className="arn-product-tile__colors"
                isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
              >
                {clusterViewLength > 1
                  ? `${clusterViewLength} ${t('ProductTile.Labels.colors')}`
                  : getProductColorLabel(currentProduct!)}
              </StyledProductVariants>
            )}
          </StyledSquare>
        </StyledProductSubWrapper>

        <div className={clsx(styles.footer, variant === 'cms-products-module' && 'hp-variant')}>
          <StyledProductDescriptionAnchor
            aria-label={primaryBadge || `${name}_${modelCode}`}
            href={getMocoLinkTo(selectedViewCluster)}
            className="arn-product-tile__inner"
            data-element-id={tileDataElementId}
            data-description={`${siteName}_${name}_${modelCode}`}
            isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
          >
            <StyledProductTileFooter className="arn-product-tile__bottom">
              <StyledProductTileDescription
                isPDP={isPDP! || !!isSearchLayer}
                hasFavIcon={productType !== 'contact-lenses'}
              >
                {productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES && (
                  <StyledCLProductBrandName>{getCLBrand(product)}</StyledCLProductBrandName>
                )}
                <StyledProductName type={productType}>{name ? name : '\u00A0'}</StyledProductName>
                {showBrand && <StyledProductBrandName>{brand}</StyledProductBrandName>}

                {selectedViewCluster.price ? (
                  <ProductPriceAlgolia
                    isCompact={mobileListingLayoutBasedOnPages !== 'full'}
                    price={selectedViewCluster.price}
                    productType={productType}
                    orders={{ current: 2, initial: 1, discountAmount: 3 }}
                    isRoxable={isRoxable}
                    isAbsoluteDiscount={shouldShowAbsoluteDiscount(selectedViewCluster)}
                    soldOutStatus={soldOutStatus}
                  />
                ) : null}
                {showSoldOutBadge && (
                  <ProductPriceSoldOutBox isPDP={isPDP}>{t('ProductTile.Labels.soldout')}</ProductPriceSoldOutBox>
                )}
              </StyledProductTileDescription>
            </StyledProductTileFooter>
          </StyledProductDescriptionAnchor>

          {showFavouritesIcon && (
            <WishListButton
              className={styles.wishlistContainer}
              currentProduct={currentProduct}
              size={20}
              wrapper={true}
            />
          )}
        </div>
      </StyledProductTileWrapper>
    )
  )
}

export { ProductTileAlgolia }
