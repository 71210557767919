// Import Swiper styles
//TODO import '../index.scss'

import React, { FC, useCallback, useRef } from 'react'
import { EffectFade, Navigation } from 'swiper'
import { Swiper as SwiperClass } from 'swiper/types'
import { SwiperSlide } from 'swiper/react'
import Box from '@mui/material/Box/Box'
import { useTheme } from '@mui/material/styles'

import ProductImage, { ProductImageProps } from '../../ProductImage/ProductImage'
import { StyledControlsWrapper, StyledControlsWrapperColorLabel, StyledSwiper } from './ProductTileGallery.style'
import { getModelCodeDisplay, getModelName, getProductColorLabel } from '../../../utils/productAttributesAlgolia'

import { IAlgoliaHit } from '../../../types/product'
import { StyledProductAnchor } from './ProductTile.style'
import { transformAttachmentsToImage } from '../../../features/plp/algoliaUtils'
import config from '@configs/index'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const siteName = config.name

type ProductTileGalleryProps = {
  isDisplayed?: boolean
  mocoLink: string
  replaceUrl?: boolean
  tileDataElementId: string
  priorityBadgeText: string
  currentProductIndex: number
  cluster: IAlgoliaHit[]
  commonImageProps?: ProductImageProps
  onIndexChange: (ind: number) => void
  saveAlgoliaFilters: () => void
}

const ProductTileGalleryAlgolia: FC<ProductTileGalleryProps> = ({
  isDisplayed = true,
  mocoLink,
  replaceUrl,
  tileDataElementId,
  priorityBadgeText,
  cluster,
  currentProductIndex,
  commonImageProps,
  onIndexChange,
  saveAlgoliaFilters,
}) => {
  const theme = useTheme()
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]
  const name = getModelName(currentProduct)
  const modelCode = getModelCodeDisplay(currentProduct)

  const slidePrev = (e: React.MouseEvent) => {
    e.stopPropagation()
    const prevSlideIndex = (swiperRef.current?.realIndex || 0) - 1
    const slideTo = prevSlideIndex < 0 ? cluster.length - 1 : prevSlideIndex
    swiperRef.current?.slideTo(slideTo)
    onIndexChange(slideTo)
  }

  const slideNext = (e: React.MouseEvent) => {
    e.stopPropagation()
    const nextSlideIndex = (swiperRef.current?.realIndex || 0) + 1
    const slideTo = nextSlideIndex > cluster.length - 1 ? 0 : nextSlideIndex
    swiperRef.current?.slideTo(slideTo)
    onIndexChange(slideTo)
  }

  const onActiveIndexChanged = useCallback(
    (s: SwiperClass) => {
      onIndexChange(s.realIndex)
    },
    [onIndexChange]
  )

  return (
    <>
      <StyledSwiper
        isDisplayed={isDisplayed}
        effect="fade"
        touchMoveStopPropagation={true}
        navigation={false}
        modules={[Navigation, EffectFade]}
        onSwiper={swiper => (swiperRef.current = swiper)}
        onActiveIndexChange={onActiveIndexChanged}
      >
        {cluster.map(c => (
          <SwiperSlide key={c.partnumberId}>
            <StyledProductAnchor
              aria-label={priorityBadgeText || `${name}_${modelCode}`}
              href={mocoLink}
              replace={replaceUrl}
              className="product-tile__inner"
              data-element-id={tileDataElementId}
              data-description={`${siteName}_${name}_${modelCode}`}
              callback={saveAlgoliaFilters}
            >
              <ProductImage attachments={transformAttachmentsToImage(c.attachments)} {...commonImageProps} />
            </StyledProductAnchor>
          </SwiperSlide>
        ))}
      </StyledSwiper>

      <StyledControlsWrapper isDisplayed={isDisplayed}>
        {cluster.length > 1 ? (
          <>
            <div className="icon-container">
              {/* TODO: wrap in a <button> */}
              <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} onClick={slidePrev} />
            </div>
            <StyledControlsWrapperColorLabel>{`${getProductColorLabel(
              currentProduct
            )}`}</StyledControlsWrapperColorLabel>
            <div className="icon-container">
              {/* TODO: wrap in a button */}
              <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} onClick={slideNext} />
            </div>
          </>
        ) : (
          <Box flex={1} textAlign="center">
            {getProductColorLabel(currentProduct)}
          </Box>
        )}
      </StyledControlsWrapper>
    </>
  )
}

export default ProductTileGalleryAlgolia
