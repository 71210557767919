import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import theme from '../../../themes'
import { IconButton } from '../../UI'
import { IAlgoliaHit, IProduct } from '../../../types/product'
import { wishlistItemSelector } from '../../../features/wishList/selector'
import {
  TRACKER_ADD_TO_WISH_LIST,
  TRACKER_REMOVE_FROM_WISH_LIST,
} from '../../../foundation/analytics/tealium/constants/tracker'
import { useWishlist } from '@views/ProductDetails/hooks/useWishlist'
import { StyledLoader } from '@components/UI/Loader/Loader'
import { sendPLPAddToFavoritesEvent } from '@features/plp/algoliaUtils'
import { loginStatusSelector } from '@redux/selectors/user'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/WishlistButton.module.scss'

export interface IProductDetailsList {
  className?: string
  currentProduct: IProduct | IAlgoliaHit
  size?: number
  wrapper?: boolean
}
interface LoaderContentProps {
  loaderSize: {
    size: number
  }
  wrapper?: boolean
}
interface HeartIconContentProps {
  itemIdWishList: string
  heartIconSize: {
    width: number | undefined
    height: number | undefined
  }
}

const LoaderContent: React.FC<LoaderContentProps> = ({ loaderSize, wrapper }) => {
  if (wrapper)
    return (
      <div className={styles.loadingWrapper}>
        <StyledLoader {...loaderSize} />
      </div>
    )

  return <StyledLoader {...loaderSize} />
}

const HeartIconContent: React.FC<HeartIconContentProps> = ({ itemIdWishList, heartIconSize }) => {
  if (itemIdWishList)
    return (
      <SVGIcon
        library="favorite"
        name="fav-heart-filled"
        {...heartIconSize}
        aria-label="Heart filled icon"
        color={theme.palette.background.dark.primary}
        size={20}
      />
    )

  return (
    <SVGIcon
      library="favorite"
      name="fav-heart"
      {...heartIconSize}
      aria-label="Heart icon"
      color={theme.palette.background.dark.primary}
      size={20}
    />
  )
}

export const WishListButton = ({ className, currentProduct, size, wrapper }: IProductDetailsList) => {
  const { determineWishListAction } = useWishlist(currentProduct)
  const [isToggling, setIsToggling] = useState(true)
  const [itemIdWishList, setItemIdWishList] = useState<string>('')

  const isLoggedIn = useSelector(loginStatusSelector)

  const partNumberId = useMemo(() => {
    return 'partnumberId' in currentProduct
      ? currentProduct.partnumberId
      : 'partNumber' in currentProduct
        ? currentProduct.partNumber
        : ''
  }, [currentProduct])
  const getItemWishList = useSelector(wishlistItemSelector(partNumberId))

  const heartIconSize = useMemo(() => {
    return { width: size || undefined, height: size || undefined }
  }, [size])
  const loaderSize = useMemo(() => {
    return size ? { size } : { size: 16 }
  }, [size])

  const sendAnalyticsEvent = () => sendPLPAddToFavoritesEvent(currentProduct as IAlgoliaHit, isLoggedIn)

  useEffect(() => {
    if (currentProduct) {
      if (getItemWishList && getItemWishList.partNumber === partNumberId) {
        setItemIdWishList(getItemWishList.giftListItemID)
      } else {
        setItemIdWishList('')
      }
    }

    setIsToggling(false)
  }, [currentProduct, getItemWishList, partNumberId])

  return (
    <IconButton
      aria-label={`${itemIdWishList ? 'Remove from' : 'Add to'} wishlist`}
      className={className}
      data-element-id={itemIdWishList ? TRACKER_REMOVE_FROM_WISH_LIST : TRACKER_ADD_TO_WISH_LIST}
      onClick={event => {
        event.preventDefault()

        if (!isToggling) {
          setIsToggling(true)
          determineWishListAction()
          sendAnalyticsEvent()
        }
      }}
    >
      {isToggling ? (
        <LoaderContent loaderSize={loaderSize} wrapper={wrapper} />
      ) : (
        <HeartIconContent itemIdWishList={itemIdWishList} heartIconSize={heartIconSize} />
      )}
    </IconButton>
  )
}
