import styled from '@mui/material/styles/styled'
import { GridItem, GridContainer } from '@components/UI/Grid'

export const ProductsWrapper = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrapper',
})(() => ({}))

export const StyledProductsCount = styled('div', {
  name: 'ProductGrid',
  slot: 'ProductsCount',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(8),
}))

export const StyledLoaderContainer = styled('div', {
  name: 'ProductGrid',
  slot: 'LoaderContainer',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const StyledProductGridContainer = styled(GridContainer, {
  name: 'ProductGrid',
  slot: 'Container',
  shouldForwardProp: propName => propName !== 'showFullWidthProducts',
})<{ showFullWidthProducts?: boolean }>(({ showFullWidthProducts, theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
  gridColumnGap: theme.spacing(4),
  gridTemplateColumns: showFullWidthProducts ? 'unset' : 'repeat(4, 1fr)',

  [theme.breakpoints.down('sm')]: {
    display: showFullWidthProducts ? 'block' : 'grid',
  },

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: showFullWidthProducts ? 'unset' : 'repeat(6, 1fr)',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    gridColumnGap: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
}))

export const StyledGridProductLayout = styled(GridItem, {
  name: 'ProductGrid',
  slot: 'Layout',
  shouldForwardProp: prop => prop !== 'listingLayout',
})<{ listingLayout?: 'full' | 'compact' }>(({ theme, listingLayout }) => ({
  gridColumn: 'span 12',
  aspectRatio: 'initial',
  maxWidth: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: theme.palette.custom.white,

  [theme.breakpoints.down('md')]: {
    padding: listingLayout === 'full' ? `0 ${theme.spacing(25)}` : 0,
  },

  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },

  /* For older browser versions that don't support aspect-ratio */
  '@supports not (aspect-ratio: 1 / 1)': {
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '150%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '100%',
      },
    },
  },
}))

export const StyledProductWrap = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrap',
})`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
  /* For older browser versions that don't support aspect-ratio */
  @supports not (aspect-ratio: 1 / 1) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`
