import { StyledProductTileWrapper, StyledLoadingWrapper, StyledSkeletonWrapper } from './ProductTile.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react'
import { PreLoader } from '../../UI'
import theme from '../../../themes'
import { useMediaQuery } from '@mui/material'

export const ProductTileLoading = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledProductTileWrapper>
      <StyledLoadingWrapper>
        <PreLoader size={isMobile ? 42 : 84} />
      </StyledLoadingWrapper>
      <StyledSkeletonWrapper>
        <SkeletonTheme baseColor="transparent" highlightColor={'rgba(0, 0, 0, 0.1)'}>
          {' '}
          <Skeleton
            style={{
              height: '8px',
              width: '20%',
              borderRadius: 0,
            }}
          />
          <Skeleton
            style={{
              height: '18px',
              width: '30%',
              borderRadius: 0,
            }}
          />
          <Skeleton
            style={{
              height: '18px',
              width: '50%',
              borderRadius: 0,
            }}
          />
        </SkeletonTheme>
      </StyledSkeletonWrapper>
    </StyledProductTileWrapper>
  )
}
